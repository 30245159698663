<template>
  <section class="aba-formulario-principal">
    <div class="pa-0 mt-4">
      <v-row>
        <v-col sm="12" md="4" cols="12" class="padding-bottom-half">
          <v-text-field
            label="CNPJ"
            :name="`cnpj`"
            v-validate="'required'"
            data-vv-scope="formulario"
            :hint="errors.first(`formulario.cnpj`)"
            persistent-hint
            :error="errors.collect(`formulario.cnpj`).length ? true : false"
            data-vv-as="cnpj"
            data-vv-validate-on="change|blur|input"
            v-model="empresa.empresa.cnpj"
            type="text"
            outlined
            v-mask="['##.###.###/####-##']"
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="4" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Inscrição estadual"
            :name="`inscricao_estadual`"
            v-validate="'required'"
            data-vv-scope="formulario"
            :hint="errors.first(`formulario.inscricao_estadual`)"
            persistent-hint
            :error="
              errors.collect(`formulario.inscricao_estadual`).length
                ? true
                : false
            "
            data-vv-as="inscrição estadual"
            data-vv-validate-on="change|blur|input"
            v-model="empresa.empresa.inscricao_estadual"
            type="text"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="4" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Inscrição municipal"
            :name="`inscricao_municipal`"
            v-validate="'required'"
            data-vv-scope="formulario"
            :hint="errors.first(`formulario.inscricao_municipal`)"
            persistent-hint
            :error="
              errors.collect(`formulario.inscricao_municipal`).length
                ? true
                : false
            "
            data-vv-as="inscrição municipal"
            data-vv-validate-on="change|blur|input"
            v-model="empresa.empresa.inscricao_municipal"
            type="text"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="6" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Razão social"
            :name="`nome`"
            v-validate="'required'"
            data-vv-scope="formulario"
            :hint="errors.first(`formulario.nome`)"
            persistent-hint
            :error="errors.collect(`formulario.nome`).length ? true : false"
            data-vv-as="razão social"
            data-vv-validate-on="change|blur|input"
            v-model="empresa.empresa.nome"
            type="text"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="6" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Nome da fantasia"
            :name="`fantasia`"
            v-validate="'required'"
            data-vv-scope="formulario"
            :hint="errors.first(`formulario.fantasia`)"
            persistent-hint
            :error="errors.collect(`formulario.fantasia`).length ? true : false"
            data-vv-as="nome da fantasia"
            data-vv-validate-on="change|blur|input"
            v-model="empresa.empresa.fantasia"
            type="text"
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" md="6" cols="12" class="padding-bottom-half">
          <v-text-field
            label="E-mail para envio de nota fiscal"
            :name="`email_nf`"
            v-validate="'required'"
            data-vv-scope="formulario"
            :hint="errors.first(`formulario.email_nf`)"
            persistent-hint
            :error="errors.collect(`formulario.email_nf`).length ? true : false"
            data-vv-as="e-mail"
            data-vv-validate-on="change|blur|input"
            v-model="empresa.contato.email_nf"
            type="text"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="6" cols="12" class="padding-bottom-half">
          <v-text-field
            label="E-mail"
            :name="`email`"
            v-validate="'required'"
            data-vv-scope="formulario"
            :hint="errors.first(`formulario.email`)"
            persistent-hint
            :error="errors.collect(`formulario.email`).length ? true : false"
            data-vv-as="e-mail"
            data-vv-validate-on="change|blur|input"
            v-model="empresa.contato.email"
            type="text"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="6" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Telefone"
            :name="`telefone`"
            v-validate="'required'"
            data-vv-scope="formulario"
            :hint="errors.first(`formulario.telefone`)"
            persistent-hint
            :error="errors.collect(`formulario.telefone`).length ? true : false"
            data-vv-as="telefone"
            data-vv-validate-on="change|blur|input"
            v-model="empresa.contato.telefone"
            type="text"
            outlined
            v-mask="['(##) #####-####', '(##) ####-####']"
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="6" cols="12" class="padding-bottom-half">
          <v-text-field
            label="site"
            :name="`site`"
            v-validate="'required'"
            data-vv-scope="formulario"
            :hint="errors.first(`formulario.site`)"
            persistent-hint
            :error="errors.collect(`formulario.site`).length ? true : false"
            data-vv-as="site"
            data-vv-validate-on="change|blur|input"
            v-model="empresa.contato.site"
            type="email"
            placeholder="Seu site www.suaempresa.com.br"
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" md="4" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Capital Social"
            :name="`capital_social`"
            persistent-hint
            v-model="empresa.empresa.capital_social"
            v-money="money"
            type="text"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="4" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Data abertura"
            :name="`nome`"
            v-validate="'required'"
            data-vv-scope="formulario"
            :hint="errors.first(`formulario.abertura`)"
            persistent-hint
            :error="errors.collect(`formulario.abertura`).length ? true : false"
            data-vv-as="data da abertura"
            data-vv-validate-on="change|blur|input"
            v-model="empresa.empresa.abertura"
            v-mask="['##/##/####']"
            type="text"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="4" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Porte"
            :name="`porte`"
            persistent-hint
            v-model="empresa.empresa.porte"
            type="text"
            outlined
            disabled
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="4" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Tipo"
            :name="`tipo`"
            persistent-hint
            v-model="empresa.empresa.tipo"
            type="text"
            outlined
            disabled
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="4" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Natureza juridica"
            :name="`natureza_juridica`"
            persistent-hint
            v-model="empresa.empresa.natureza_juridica"
            type="text"
            outlined
            disabled
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="4" cols="12" class="padding-bottom-half">
          <v-text-field
            label="Última Atualização"
            :name="`ultima_atualizacao`"
            persistent-hint
            v-model="empresa.empresa.ultima_atualizacao"
            type="text"
            v-mask="['##/##/####']"
            outlined
            disabled
          >
          </v-text-field>
        </v-col>
        <v-col sm="12" md="12" cols="12" class="padding-bottom-half">
          <v-checkbox v-model="empresa.empresa.iss">
            <template v-slot:label>
              <div>
                Sua empresa está enquadrada em alguma categoria cuja retenção do
                ISS, nas Notas Fiscais emitidas pela SOCIIS RH, seja obrigatória
                ?
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col sm="12" md="12" cols="12" class="padding-bottom-half">
          <v-checkbox v-model="empresa.empresa.simples">
            <template v-slot:label>
              <div>Optante pelo Simples Nacional?</div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" justify="end">
          <v-btn
            elevation="2"
            x-large
            color="primary"
            label="Salvar e finalizar"
            @click="finalizarCadastro()"
            :loading="loading"
          >
            Salvar e finalizar</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: "principal",
  inject: ["$validator"],
  data: () => ({
    objetoForm: {},
    loading: false,
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
  }),
  methods: {
    finalizarCadastro() {
      Promise.all([
        this.$validator.validate("formulario.site"),
        this.$validator.validate("formulario.email_nf"),
      ]).then((result) => {
        if (result.every((isValid) => isValid)) {
          this.loading = true;
          this.$store
            .dispatch("empresas/salvarEmpresaFormularioExterno", this.empresa)
            .then((data) => {
              if (data != "NAO_AUTORIZADO") {
                this.cadastroConcluido = true;
                this.dialog = true;
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem: "Cadastrado com sucesso.",
                  cor: "green",
                });
              }
            })
            .catch(() => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: "Erro ao cadastrar, tente novamente mais tarde",
                cor: "red",
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  computed: {
    empresa: {
      get() {
        return this.$store.getters["empresas/getEmpresaUsuarios"];
      },
      set(value) {
        this.$store.dispatch("empresas/setEmpresaUsuarios", value);
      },
    },
  },
};
</script>

<style></style>
